import React, { useEffect, useRef, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { Popover, PopoverPlacement } from '../../../Overlays';

const DEFAULT_OPTION_TOOLTIP_TIMEOUT = 1000;

export interface SelectDropdownOptionProps extends React.ComponentProps<typeof Combobox['Option']> {
  /**
   * Flag to indicate if the tooltip can be show
   */
  showTooltip?: boolean;
}

/**
 * `SelectComboboxOption` represents an option within a `SelectCombobox` component
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created 2023-05-10
 */
export const SelectDropdownOption = ({ showTooltip = true, children, ...restProps }: SelectDropdownOptionProps) => {
  const [openTooltip, setOpenTooltip] = useState(false);
  const popoverTimeout = useRef<NodeJS.Timeout | undefined>();
  const optionContentRef = useRef<HTMLSpanElement>(null);
  const [isContentOverflowing, setIsContentOverflowing] = useState(false);

  const handleOptionMouseEnter = () => {
    if (!isContentOverflowing || !showTooltip) return;

    popoverTimeout.current = setTimeout(() => {
      setOpenTooltip(true);
    }, DEFAULT_OPTION_TOOLTIP_TIMEOUT);
  };

  const handleOptionMouseLeave = () => {
    if (!isContentOverflowing) return;
    setOpenTooltip(false);

    if (popoverTimeout.current) {
      clearTimeout(popoverTimeout.current);
      popoverTimeout.current = undefined;
    }
  };

  useEffect(() => {
    const currentOptionRef = optionContentRef?.current;
    if (!currentOptionRef) return;

    const handleWindowResize = () => {
      const { scrollWidth, clientWidth } = currentOptionRef;
      setIsContentOverflowing(scrollWidth > clientWidth);
    };

    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const renderContent = ({ active }: { active: boolean }) => {
    const optionContent = (
      <div className="e-flex e-min-w-0 e-overflow-hidden e-w-full e-relative">
        <span ref={optionContentRef} className="e-text-neutral-50 e-truncate e-text-sm">
          {children}
        </span>
      </div>
    );

    // Display a tooltip with the option content if overflows
    if (isContentOverflowing)
      return (
        <Popover
          role="tooltip"
          isOpen={openTooltip && active}
          content={
            <div className="e-text-xs e-p-2 e-border-0.5 e-border-neutral-500 e-bg-neutral-600 e-text-left e-rounded-lg">
              {children}
            </div>
          }
          placement={PopoverPlacement.bottomEnd}
          menuClassName="e-w-64 e-overflow-hidden">
          {optionContent}
        </Popover>
      );

    return optionContent;
  };

  return (
    <Combobox.Option {...restProps} onMouseEnter={handleOptionMouseEnter} onMouseLeave={handleOptionMouseLeave}>
      {renderContent}
    </Combobox.Option>
  );
};
