import React, { ChangeEvent, FocusEvent, useEffect, useRef, useState } from 'react';
import { TextInput, TextInputProps } from '../TextInput/TextInput';
import { IconCatalog } from '../../Icon/Icon';

export interface SearchBarProps extends Omit<TextInputProps, 'onEndIconClick' | 'endIcon'> {
  /**
   * Provide a handler that is called when the close button was clicked.
   */
  onCleanClick?: () => void;
}

/**
 * Search enables users to specify a word or a phrase to find relevant pieces of content or filter a long list of elements. Commonly represented as a Text Input with a little magnifying glass inside of it.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2022-05-24
 */
export const SearchBar = React.forwardRef<HTMLInputElement, SearchBarProps>(
  ({ value, onChange, onBlur, onFocus, onCleanClick, ...restOfProps }, ref) => {
    const [currentValue, setCurrentValue] = useState(value);
    const defaultRef = useRef(null);
    const resolvedRef: any = ref || defaultRef;

    useEffect(() => {
      setCurrentValue(value);
    }, [value]);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
      setCurrentValue(event.target.value);
      if (onChange) onChange(event);
    };

    const handleInputBlur = (event: FocusEvent<HTMLInputElement>): void => {
      if (onBlur) onBlur(event);
    };

    const handleInputFocus = (event: FocusEvent<HTMLInputElement>): void => {
      if (onFocus) onFocus(event);
    };

    const handleCleanClick = (): void => {
      if (!currentValue) return;

      setCurrentValue('');
      resolvedRef.current.focus();
      if (onCleanClick) onCleanClick();
    };

    /* Render JSX */
    return (
      <TextInput
        ref={resolvedRef}
        startIcon={IconCatalog.search}
        endIcon={currentValue && currentValue !== '' ? IconCatalog.close : undefined}
        value={currentValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        onEndIconClick={handleCleanClick}
        {...restOfProps}
      />
    );
  },
);
