import React from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';

interface ModalRootProps {
  /**
   * Elements to display inside the ModalRoot.
   */
  children: React.ReactNode;

  /**
   * Optional classname prop to include classNames to the modal's root element
   */
  className?: string;
}

/**
 * `ModalRoot` represents the main container where modals will be rendered.
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2022-09-27
 */
export const ModalRoot = ({ children, className }: ModalRootProps) => {
  const classes = {
    root: cn(
      'e-fixed e-top-0 e-left-0 e-bottom-0 e-right-0 e-z-30 e-overflow-x-hidden e-overflow-y-auto e-outline-0 e-transition-opacity',
      className,
    ),
    container: cn('e-flex e-min-h-full e-justify-center e-p-4 e-items-center'),
    overlay: cn('e-fixed e-inset-0 e-bg-neutral-500/75 e-transition-opacity'),
  };

  // it's needed to get the 70% of the screen height
  const BASE_MODAL_SIZE_GAP = 'calc(100% - 3.5rem)';

  const getModalStyles = () => {
    const defaultStyles = {
      height: BASE_MODAL_SIZE_GAP,
    };

    return defaultStyles;
  };

  const modalRoot = (
    <div className={classes.root} aria-labelledby="modal-title" role="dialog" aria-modal="true">
      {/* OVERLAY */}
      <div className={classes.overlay} aria-hidden="true"></div>

      {/* MODAL CONTAINER */}
      <div className={classes.container} style={getModalStyles()}>
        {children}
      </div>
    </div>
  );

  return ReactDOM.createPortal(modalRoot, document.body);
};
