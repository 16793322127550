import React from 'react';
import cn from 'classnames';
import { Button, ButtonSize, ButtonVariant } from '../../../Buttons';
import { IconCatalog } from '../../../Icon/Icon';

export interface ModalHeaderProps {
  /**
   * Title for modal header
   */
  title?: string;

  /**
   * Render close button
   */
  hasCloseBtn?: boolean;

  /**
   * Set an extra action on the modal header
   */
  action?: React.ReactNode;

  /**
   * Callback to execute when clicking on the close button
   */
  onCloseBtnClick?: () => void;
}

/**
 * `ModalFooter` represents the header section of a modal
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2023-03-21
 */
export const ModalHeader = ({ title, action, hasCloseBtn, onCloseBtnClick }: ModalHeaderProps) => {
  const classes = {
    header: cn('e-flex e-items-center e-flex-shrink-0 e-justify-between e-pt-6 e-pb-4 e-px-6'),
  };

  return (
    <div className={classes.header}>
      {title && <h2 className="e-text-xl e-font-semi-bold e-text-neutral-50 e-line-clamp-3">{title}</h2>}

      <div className="e-flex e-items-center e-ml-auto">
        {action}
        {hasCloseBtn && (
          <Button
            startIcon={IconCatalog.close}
            variant={ButtonVariant.ghost}
            size={ButtonSize.xs}
            onClick={onCloseBtnClick}
          />
        )}
      </div>
    </div>
  );
};
