import React from 'react';
import cn from 'classnames';

export const RowItemSkeleton = () => {
  const classes = {
    container: cn('e-w-full e-flex e-flex-col e-gap-2 e-p-4 e-border-b e-border-neutral-600'),
    children: cn('e-rounded-sm e-h-4 e-bg-neutral-600 e-animate-skeleton'),
  };

  return (
    <div className={classes.container}>
      <div className={cn(classes.children, 'e-w-1/2')} />
      <div className={cn(classes.children, 'e-w-1/3')} />
    </div>
  );
};
