import React, { useEffect } from 'react';
import cn from 'classnames';
import { Key, useBodyClass, useKeyPress, useWindowSize } from '../../../../common';
import { ModalBody } from './ModalBody';
import { ModalListBody } from './ModalListBody';
import { ModalHeader } from './ModalHeader';
import { ModalSearchHeader } from './ModalSearchHeader';
import { ModalFooter } from './ModalFooter';

export const PRIMARY_ACTION_BTN_ID = 'primary-action-btn';

export enum ModalSize {
  base = 'base',
  lg = 'lg',
  full = 'full',
}

const Sizes: Record<ModalSize, string> = {
  [ModalSize.base]: 'e-max-w-xl',
  [ModalSize.lg]: 'e-max-w-4xl',
  [ModalSize.full]: 'e-w-full e-h-full',
};

type ModalContentProps = {
  /**
   * Specify an optional id to be added to the primary action button.
   */
  primaryActionBtnId?: string;

  /**
   * Specify an optional className to be added to the body section.
   */
  className?: string;

  /**
   * Changes the size of the modal, giving it more or less padding
   */
  size?: ModalSize;

  /**
   * The children of the modal content.
   */
  children?: React.ReactNode;

  /**
   * The callback to get notified when the user press the ESC key
   */
  onEscKeyPress?: () => void;
};

/**
 * `ModalContent` is the main container where the different sections of the modal must be used
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2023-03-21
 */
export const ModalContent = ({
  primaryActionBtnId = PRIMARY_ACTION_BTN_ID,
  className,
  size = ModalSize.base,
  children,
  onEscKeyPress,
}: ModalContentProps) => {
  const isFullScreen = size === ModalSize.full;
  const BREAKPOINT_WINDOW_HEIGHT = 1024;
  useBodyClass('e-opened-modal');
  const { windowHeight } = useWindowSize();

  const classes = {
    content: cn(
      'e-bg-neutral-900',
      'e-shadow-xl e-rounded-2xl',
      'e-overflow-hidden',
      'e-relative e-flex e-flex-col e-w-full e-pointer-events-auto e-bg-clip-padding e-outline-0',
      Sizes[size],
      {
        'e-max-h-[90%]': !isFullScreen && windowHeight <= BREAKPOINT_WINDOW_HEIGHT,
        'e-max-h-[1024px]': !isFullScreen && windowHeight > BREAKPOINT_WINDOW_HEIGHT,
      },
      className,
    ),
  };

  const pressedEsc = useKeyPress(Key.Escape);
  const pressedEnter = useKeyPress(Key.Enter);

  useEffect(() => {
    if (pressedEnter) {
      const btn = document.querySelector(`[data-id="${primaryActionBtnId}"]`);
      if (btn) (btn as HTMLButtonElement).click();
    }
  }, [pressedEnter]);

  useEffect(() => {
    if (pressedEsc) handleCancelBtnClick();
  }, [pressedEsc]);

  const handleCancelBtnClick = () => {
    if (onEscKeyPress) onEscKeyPress();
  };

  return <div className={classes.content}>{children}</div>;
};

ModalContent.Header = ModalHeader;
ModalContent.SearchHeader = ModalSearchHeader;
ModalContent.Body = ModalBody;
ModalContent.ListBody = ModalListBody;
ModalContent.Footer = ModalFooter;
