import React from 'react';
import cn from 'classnames';
import { Tabs, TabsProps } from '@radix-ui/react-tabs';

export interface TabsRootProps extends TabsProps {}

export const TabsRoot = React.forwardRef<HTMLDivElement, TabsRootProps>((props, forwardedRef) => {
  const { className, children, onValueChange, ...rest } = props;
  const classes = {
    container: cn(className),
  };

  return (
    <Tabs onValueChange={onValueChange} className={classes.container} ref={forwardedRef} {...rest}>
      {children}
    </Tabs>
  );
});
