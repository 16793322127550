import React from 'react';
import cn from 'classnames';
import { TabsContent as RadixTabsContent, TabsContentProps as RadixTabsContentProps } from '@radix-ui/react-tabs';

export interface TabsContentProps extends RadixTabsContentProps {}

export const TabsContent = React.forwardRef<HTMLDivElement, TabsContentProps>((props, forwardedRef) => {
  const { className, children, ...rest } = props;
  const classes = {
    container: cn(className),
  };
  return (
    <RadixTabsContent className={classes.container} ref={forwardedRef} {...rest}>
      {children}
    </RadixTabsContent>
  );
});
