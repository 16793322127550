import { useEffect, useRef } from 'react';

export const useBlockOutsideScroll = (isActive: boolean) => {
  const scrollableElements = useRef<HTMLElement[]>([]);

  useEffect(() => {
    if (isActive) {
      scrollableElements.current = Array.from(document.querySelectorAll('body *')).filter(
        (el: any) => getComputedStyle(el).overflow === 'auto' || getComputedStyle(el).overflow === 'scroll',
      ) as HTMLElement[];
      scrollableElements.current.forEach((el) => (el.style.overflow = 'hidden'));
    } else {
      scrollableElements.current.forEach((el) => (el.style.overflow = ''));
      scrollableElements.current = [];
    }
  }, [isActive]);
};
