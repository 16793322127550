import React, { forwardRef, FocusEvent, Fragment, useMemo, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Combobox, Transition, Portal } from '@headlessui/react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Modifier, usePopper } from 'react-popper';
import {
  FormFieldState,
  Locale,
  SelectOption,
  Translations,
  setMaxHeightByOptions,
  useBlockOutsideScroll,
} from '../../../common';
import type { SelectProps } from '../Select/Select';
import { Icon, IconCatalog } from '../../Icon/Icon';
import { Scrollable } from '../../Scrollable/Scrollable';
import { Spinner, SpinnerSize, SpinnerVariant } from '../../Loaders';
import { SelectDropdownOption } from './SelectDropdownOption/SelectDropdownOption';

const MAX_HEIGHT = '300px';
const MAX_OPTIONS = 7;

export enum FallbackPlacements {
  top = 'top',
  right = 'right',
  left = 'left',
  bottom = 'bottom',
}

const translations: Translations = {
  en: {
    loading: 'Loading',
    noResults: 'No results found',
  },
  es: {
    loading: 'Cargando',
    noResults: 'No se encontraron resultados',
  },
  pt: {
    loading: 'Carregando',
    noResults: 'Nenhum resultado encontrado',
  },
};

export interface SelectDropdownStatelessProps extends Omit<SelectProps, 'onFocus' | 'onBlur'> {
  /**
   * Flag to determine if we should display the input value in the dropdown.
   * Default is set to true.
   */

  shouldDisplayInputValue?: boolean;
  /**
   * The option for blocking the body scroll when the dropdown is open
   */
  blockBodyScroll?: boolean;

  /**
   * Callback to execute when clicking on the chevron button. It executes opening the dropdown
   * plus any other custom logic passed on this callback.
   */
  onChevronButtonClick?: () => void;

  /**
   * Whether the dropdown has more results to load
   */
  hasMoreResults?: boolean;

  /**
   * The locale to be used by the component
   */
  locale: Locale;

  /**
   * The trigger ref coming from the host component
   */
  triggerRef?: React.RefObject<HTMLButtonElement>;

  /**
   * The menu container ref coming from the host component
   */
  menuContainerRef?: React.RefObject<HTMLElement>;

  /**
   * Set the maximum height for the dropdown menu
   */
  menuMaxHeight?: string;

  /**
   * Ser the maximum number of options to be displayed
   */
  menuMaxOptions?: number;

  /**
   * The fallback placements for the dropdown menu
   */
  menuFallbackPlacements?: Array<FallbackPlacements>;

  /**
   * The callback to get notified when the select input value changes
   */
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;

  /**
   * The callback to get notified when the select input is blurred
   */
  onBlur?: (event: FocusEvent<HTMLDivElement>) => void;

  /**
   * The callback to get notified when the select input is focused
   */
  onFocus?: (event: FocusEvent<HTMLDivElement>) => void;

  /**
   * The callback to get notified when the dropdown is scrolled to the bottom
   */
  onLoadMore?: () => void;
}

/**
 * `SelectDropdownStateless` represents a list from where to select an option
 * @author Sergio Ruiz <sergioruizdavila@gmail.com>
 * Created at 2023-07-21
 */
export const SelectDropdownStateless = forwardRef<HTMLInputElement, SelectDropdownStatelessProps>(
  (
    {
      assistiveText,
      className = '',
      menuClassName,
      fieldState = FormFieldState.default,
      id,
      preloadOptions = [],
      options = [],
      placeholder,
      value,
      dataTestId,
      blockBodyScroll = true,
      isDisabled = false,
      isFullWidth = false,
      isLoading = false,
      isOptionsLoading = false,
      isRequired = false,
      label,
      shouldDisplayInputValue = true,
      name,
      onChevronButtonClick,
      locale = Locale.es,
      hasMoreResults = false,
      triggerRef,
      menuContainerRef,
      menuMaxHeight = MAX_HEIGHT,
      menuMaxOptions = MAX_OPTIONS,
      menuFallbackPlacements = [FallbackPlacements.top],
      onBlur,
      onChange,
      onInputChange,
      onFocus,
      onLoadMore = () => {},
    },
    forwardedRef,
  ) => {
    const [popoverElement, setPopoverElement] = useState<HTMLUListElement | null>(null);
    const defaultTriggerRef = useRef(null);
    const elementRef = useRef(null);
    const resolvedTriggerRef: any = triggerRef || defaultTriggerRef;
    const SKIDDING_OFFSET = 0;
    const DISTANCE_OFFSET = 8;

    /* Popper config */
    const modifiers = useMemo(
      () => [
        { name: 'offset', options: { offset: [SKIDDING_OFFSET, DISTANCE_OFFSET] } },
        {
          name: 'flip',
          options: {
            fallbackPlacements: menuFallbackPlacements,
          },
        },
        {
          name: 'sameWidth',
          enabled: true,
          fn: ({ state }) => {
            state.styles.popper.width = `${state.rects.reference.width}px`;
          },
          phase: 'beforeWrite',
          requires: ['computeStyles'],
        } as Modifier<'sameWidth'>,
      ],
      [],
    );

    const { styles, attributes, forceUpdate } = usePopper(elementRef.current, popoverElement, {
      placement: 'bottom-end',
      modifiers,
    });

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    // Filter options that are already on the preloadOptions array
    const filteredOptions = useMemo(
      () => options.filter((option) => !preloadOptions.find((preloadOption) => preloadOption.value === option.value)),
      [options, preloadOptions],
    );

    const [isFocused, setIsFocused] = useState(false);
    const isSelectActive = fieldState === FormFieldState.active && !isDisabled;
    const showInnerSpinner = isOptionsLoading || hasMoreResults;
    const [paginatedResultRef] = useInfiniteScroll({
      onLoadMore,
      hasNextPage: hasMoreResults,
      loading: isOptionsLoading,
    });
    useBlockOutsideScroll(isDropdownOpen && blockBodyScroll);

    const getInputContainerStyles = () => {
      if (isDisabled)
        return cn('e-text-neutral-300 e-bg-neutral-800 e-border-transparent e-cursor-default e-pointer-events-none');

      return cn('e-bg-neutral-700 e-text-neutral-50', {
        'e-border-neutral-400': fieldState === FormFieldState.default,
        'e-border-error-500': fieldState === FormFieldState.error,
      });
    };

    const classes = {
      container: cn(className),
      inputContainer: cn(
        'e-flex e-p-2 e-pl-4 e-cursor-pointer',
        'e-border e-rounded-lg e-appearance-none e-outline-none e-truncate',
        'e-h-10',
        {
          'e-w-full ': isFullWidth,
          'e-border-primary-500': isFocused || isSelectActive,
        },
        getInputContainerStyles(),
      ),
      input: cn(
        'e-w-full e-transition e-duration-100 e-ease-out e-outline-none e-bg-transparent',
        'e-text-base e-font-regular placeholder:e-text-neutral-300 placeholder:e-font-light placeholder:e-text-base',
      ),
      options: cn('e-z-50', menuClassName),
      optionsContent: cn(
        'e-shadow-lg e-bg-neutral-600 e-rounded-lg e-z-50 e-mt-1.5 e-w-full',
        'e-bg-neutral-600 e-rounded-lg',
        'e-border e-border-neutral-500',
        'e-text-base-white e-text-caption e-text-center',
        'e-transition e-duration-100 e-ease-out',
      ),
      option: (item: SelectOption, active: boolean) =>
        cn('e-flex e-space-x-3 e-cursor-pointer', 'e-px-3 e-py-4', 'hover:e-bg-neutral-700', {
          'e-bg-neutral-700': active || item.value === value,
        }),
      endContainer: cn('e-flex e-items-center e-space-x-2 e-self-stretch e-flex-shrink-0 e-pl-1 e-ml-auto'),
      assistiveText: cn('e-mt-2 e-text-xs e-font-extra-light', {
        'e-text-neutral-200': fieldState === FormFieldState.default,
        'e-text-error-500': fieldState === FormFieldState.error,
      }),
    };

    const handleInputBlur = (event: FocusEvent<HTMLDivElement>): void => {
      setIsFocused(false);
      if (onBlur) onBlur(event);
    };

    const handleInputFocus = (event: FocusEvent<HTMLDivElement>): void => {
      if (isDisabled) return;

      setIsFocused(true);
      if (onFocus) onFocus(event);
    };

    const handleOptionChange = (selectedValue: string) => {
      const selectedOption = getOptionByValue(selectedValue);
      if (onChange && selectedOption) onChange(selectedOption as SelectOption, name);
    };

    const getOptionByValue = (value: string) =>
      [...preloadOptions, ...options]?.find((option) => option?.value === value);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onInputChange) onInputChange(event);
    };

    const handleForceUpdate = () => {
      let timeout: ReturnType<typeof setTimeout>;
      if (forceUpdate) timeout = setTimeout(() => forceUpdate());
      return () => clearTimeout(timeout);
    };

    // Update the dropdown position, when the options change
    useEffect(() => {
      if (options.length === 0) return;
      handleForceUpdate();
    }, [options]);

    const renderOptions = (options: Array<SelectOption>, isLoading = false) => {
      if (isLoading) return null;
      const noResultsFound = options.length === 0;
      if (noResultsFound)
        return <span className="e-text-neutral-400 e-p-3 e-block">{translations[locale].noResults}</span>;

      return options.map((option) => (
        <SelectDropdownOption
          key={option.value}
          className={({ active }: { active: boolean }) => classes.option(option, active)}
          value={option?.value}>
          {option.label}
        </SelectDropdownOption>
      ));
    };

    const handleButtonClick = () => {
      if (onChevronButtonClick) onChevronButtonClick();
    };

    return (
      <Combobox
        data-testid={dataTestId}
        as="div"
        className={classes.container}
        value={value}
        onChange={handleOptionChange}>
        {({ open }) => {
          setIsDropdownOpen(open);
          return (
            <>
              {label && (
                <Combobox.Label htmlFor={id} className="e-mb-2 e-text-neutral-50 e-text-sm e-font-regular">
                  {label}
                  {isRequired && '*'}
                </Combobox.Label>
              )}
              <div className="e-relative">
                <div className={classes.inputContainer} ref={elementRef}>
                  <Combobox.Input
                    ref={forwardedRef}
                    className={classes.input}
                    displayValue={(value: string) => (shouldDisplayInputValue && getOptionByValue(value)?.label) || ''}
                    placeholder={placeholder}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    autoComplete="off"
                  />

                  <div className={classes.endContainer}>
                    {isLoading && <Spinner size={SpinnerSize.xs} variant={SpinnerVariant.primary} />}

                    {/* CHEVRON ICON */}
                    <Combobox.Button onClick={handleButtonClick} ref={resolvedTriggerRef}>
                      {({ open }) => (
                        <Icon icon={open ? IconCatalog.chevronUp : IconCatalog.chevronDown} aria-hidden="true" />
                      )}
                    </Combobox.Button>
                  </div>
                </div>

                <Portal>
                  <Transition
                    as={Fragment}
                    leave="e-transition e-ease-in e-duration-100"
                    leaveFrom="e-opacity-100"
                    leaveTo="e-opacity-0"
                    ref={menuContainerRef}>
                    <Combobox.Options
                      ref={setPopoverElement}
                      style={{ ...styles.popper }}
                      className={classes.options}
                      {...attributes.popper}>
                      <Scrollable
                        className={classes.optionsContent}
                        style={setMaxHeightByOptions({
                          listLength: options?.length,
                          maxOptions: menuMaxOptions,
                          maxHeight: menuMaxHeight,
                        })}>
                        {/* PRELOAD OPTIONS */}
                        {preloadOptions.length > 0 && renderOptions(preloadOptions)}

                        {/* OPTIONS */}
                        {renderOptions(filteredOptions, isOptionsLoading)}

                        {/* LOADING */}
                        {showInnerSpinner && (
                          <div
                            ref={paginatedResultRef}
                            className="e-flex e-items-center e-justify-center e-p-3 e-gap-2">
                            <Spinner size={SpinnerSize.xs} variant={SpinnerVariant.primary} />
                            <span className="e-text-neutral-300 e-font-medium e-text-sm">
                              {translations[locale].loading}
                            </span>
                          </div>
                        )}
                      </Scrollable>
                    </Combobox.Options>
                  </Transition>
                </Portal>
              </div>
              {assistiveText && <span className={classes.assistiveText}>{assistiveText}</span>}
            </>
          );
        }}
      </Combobox>
    );
  },
);
