import React, { ReactElement, useEffect, useState } from 'react';
import { Key, useKeyPress } from '../../common';

export interface UseListProps<T extends { itemId: string }> {
  items: Array<ReactElement<T>>;
  onItemChange: (option: ReactElement<T>) => void;
}

/**
 * Hook used for using all the list functionalities (key down/up, enter, scroll to, etc).
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2023-10-18
 */
export const useList = <T extends { itemId: string }>({ items, onItemChange }: UseListProps<T>) => {
  const defaultCursorPosition = 0;
  const [cursor, setCursor] = useState(defaultCursorPosition);

  const refs = items.reduce((acc: any, value) => {
    acc[value?.props.itemId] = React.createRef();
    return acc;
  }, {});

  const onScroll = (option?: ReactElement) => {
    if (!option) return;
    if (!refs[option.props.itemId]?.current) return;
    refs[option.props.itemId].current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };
  useEffect(() => onScroll(items.at(cursor)), [cursor]);

  const setOptionCursor = (option: ReactElement) => {
    const newCursor = items.findIndex((item) => item.props.itemId === option.props.itemId);
    setCursor(newCursor);
  };

  const onArrowDown = () => setCursor(cursor + 1);

  const onArrowUp = () => setCursor(cursor - 1);

  const onEnter = (option: ReactElement) => {
    setOptionCursor(option);
    onItemChange(option);
  };

  const pressedArrowDown = useKeyPress(Key.ArrowDown);
  const pressedArrowUp = useKeyPress(Key.ArrowUp);
  const pressedEnter = useKeyPress(Key.Enter);

  useEffect(() => {
    if (pressedEnter) onEnter(items.at(cursor) as ReactElement);
  }, [pressedEnter]);

  useEffect(() => {
    if (pressedArrowDown && cursor < items.length - 1) onArrowDown();
  }, [pressedArrowDown]);

  useEffect(() => {
    if (pressedArrowUp && cursor > 0) onArrowUp();
  }, [pressedArrowUp]);

  return { refs, onEnter, setOptionCursor, cursor };
};
