import React from 'react';
import cn from 'classnames';
import { BaseModal, BaseModalProps } from '../BaseModal/BaseModal';
import { ModalSize, PRIMARY_ACTION_BTN_ID } from '../ModalContent';
import { Button, ButtonSize, ButtonVariant } from '../../../Buttons';

export interface ConfirmationModalProps extends Omit<BaseModalProps, 'body' | 'size' | 'footer'> {
  /**
   * Set the confirm button label
   */
  confirmBtnLabel?: string;

  /**
   * Set the confirm button variant
   */
  confirmBtnVariant?: ButtonVariant;

  /**
   * Set the cancel button label
   */
  cancelBtnLabel?: string;

  /**
   * Set the confirmation description
   */
  description?: string;

  /**
   * The callback to get notified when the confirm button was clicked
   */
  onConfirm: () => void;
}

/**
 * Use this component in order to show a confirmation Dialog
 * @author Sergio Ruiz <sergio.ruiz@evacenter.com>
 * Created at 2022-09-26
 */
export const ConfirmationModal = ({
  description,
  className,
  confirmBtnLabel,
  confirmBtnVariant = ButtonVariant.primary,
  cancelBtnLabel,
  onClose,
  onConfirm,
  header,
  ...restProps
}: ConfirmationModalProps) => {
  const classes = cn(className);

  const handleCancelButtonClick = () => {
    if (onClose) onClose();
  };

  const handleConfirmButtonClick = () => {
    if (onConfirm) onConfirm();
  };

  return (
    <BaseModal
      className={classes}
      size={ModalSize.base}
      onClose={handleCancelButtonClick}
      header={header}
      body={
        <div className="flex flex-col gap-y-6">
          <p className="e-text-neutral-50">{description}</p>
        </div>
      }
      footer={
        <div className="e-flex e-items-center e-space-x-4 e-ml-auto">
          <Button onClick={handleCancelButtonClick} size={ButtonSize.sm} variant={ButtonVariant.secondary}>
            {cancelBtnLabel}
          </Button>
          <Button
            onClick={handleConfirmButtonClick}
            size={ButtonSize.sm}
            variant={confirmBtnVariant}
            data-id={PRIMARY_ACTION_BTN_ID}>
            {confirmBtnLabel}
          </Button>
        </div>
      }
      {...restProps}></BaseModal>
  );
};
