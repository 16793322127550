import React, { isValidElement, ReactElement, cloneElement, useMemo } from 'react';
import cn from 'classnames';
import { useList } from '../../../../common/hooks';
import { Scrollable } from '../../../Scrollable/Scrollable';
import { RowItemSkeleton, RowItemProps } from '../SearchModal/RowItem';

export interface ModalListBodyProps {
  /**
   * Elements to display inside the Modal.
   */
  children: Array<ReactElement<RowItemProps>>;

  /**
   * The loading element to display when fetching more results
   */
  loadingElement?: ReactElement | null;

  /**
   * Specify an optional className to be added to the body section.
   */
  className?: string;

  /**
   * Flag to turn on/off the scrollable body content
   */
  isBodyScrollable?: boolean;

  /**
   * Flag to turn on/off the skeleton loading of each item
   */
  showSkeletons?: boolean;

  /**
   * Whether the list has more results to load
   */
  isFetchingMore?: boolean;

  /**
   * The callback to get notified when an item was clicked
   */
  onItemClick?: (itemId: string) => void;
}

/**
 * `ModalListBody` represents a body section of a modal with a list of items to select from.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2023-10-18
 */
export const ModalListBody = ({
  className,
  isBodyScrollable = true,
  showSkeletons = false,
  children,
  loadingElement,
  onItemClick,
}: ModalListBodyProps) => {
  const classes = {
    body: cn('e-px-6 e-relative e-flex-auto', className),
  };

  const { refs, cursor } = useList({
    items: children,
    onItemChange: (item) => {
      if (!isValidElement(item)) return null;
      if (onItemClick) onItemClick(item.props.itemId as string);
    },
  });

  const newChildren = useMemo(() => {
    return children.map((child, index) => {
      if (!isValidElement(child)) return null;

      return cloneElement(child, {
        ref: refs[child.props.itemId],
        isFocused: index === cursor,
        onClick: () => {
          if (onItemClick) onItemClick(child.props.itemId);
        },
        ...child.props,
      } as RowItemProps);
    });
  }, [children, cursor, refs]);

  const renderBodyContent = () => {
    if (showSkeletons) return Array.from({ length: 6 }).map((_, i) => <RowItemSkeleton key={i} />);
    return (
      <>
        {newChildren}
        {loadingElement}
      </>
    );
  };

  if (isBodyScrollable) return <Scrollable className={classes.body}>{renderBodyContent()}</Scrollable>;

  return <div className={classes.body}>{renderBodyContent()}</div>;
};
