import React, { ChangeEvent, useEffect, useState } from 'react';
import cn from 'classnames';
import { Button, ButtonSize, ButtonVariant } from '../../../Buttons';
import { IconCatalog } from '../../../Icon/Icon';
import { SearchBar } from '../../../Inputs/SearchBar/SearchBar';
import { InlineAlert, InlineAlertVariant } from '../../../Notifications/InlineAlert/InlineAlert';
import { useDebounce } from '../../../../common/hooks';

export interface ModalSearchHeaderProps {
  /**
   * Title for modal header
   */
  title?: string;

  /**
   * Render close button
   */
  hasCloseBtn?: boolean;

  /**
   * Show an inline alert message
   */
  showAlertMsg?: boolean;

  /**
   * Set the alert message text
   */
  alertMsg?: string | JSX.Element | undefined;

  /**
   * Set the alert message variant
   */
  alertVariant?: InlineAlertVariant;

  /**
   * Set an extra action on the modal header
   */
  action?: React.ReactNode;

  /**
   * The default search value
   */
  defaultSearchValue?: string;

  /**
   * The placeholder to put on input's search bar.
   */
  searchBarPlaceholder?: string;

  /**
   * The ref to the search input
   */
  searchRef?: React.RefObject<HTMLInputElement>;

  /**
   * Callback to execute when the user clears the search input text.
   */
  onCleanClick?: () => void;

  /**
   * Callback to execute when the search input changes
   */
  onSearchChange?: (value: string) => void;

  /**
   * Callback to execute when clicking on the close button
   */
  onCloseBtnClick?: () => void;
}

/**
 * `ModalSearchHeader` represents the header section of a modal with a search input.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2023-10-11
 */
export const ModalSearchHeader = ({
  title,
  action,
  hasCloseBtn = false,
  showAlertMsg = false,
  alertMsg = '',
  defaultSearchValue,
  searchBarPlaceholder,
  searchRef,
  alertVariant = InlineAlertVariant.warning,
  onSearchChange,
  onCloseBtnClick,
  onCleanClick,
}: ModalSearchHeaderProps) => {
  const classes = {
    container: cn('e-flex e-flex-col e-gap-6', 'e-pt-6 e-pb-4 e-px-6'),
    header: cn('e-flex e-items-center e-flex-shrink-0 e-justify-between'),
  };

  const [searchValue, setSearchValue] = useState(defaultSearchValue);

  const debouncedSearchTerm = useDebounce(searchValue, 500);

  useEffect(() => {
    setSearchValue(defaultSearchValue);
  }, [defaultSearchValue]);

  useEffect(() => {
    if (onSearchChange) onSearchChange(debouncedSearchTerm?.toString() || '');
  }, [debouncedSearchTerm]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {title && <h2 className="e-text-xl e-leading-7 e-font-semi-bold e-text-neutral-50 e-line-clamp-3">{title}</h2>}

        <div className="e-flex e-items-center e-ml-auto">
          {action}
          {hasCloseBtn && (
            <Button
              startIcon={IconCatalog.close}
              variant={ButtonVariant.ghost}
              size={ButtonSize.xs}
              onClick={onCloseBtnClick}
            />
          )}
        </div>
      </div>

      <SearchBar
        ref={searchRef}
        autoFocus
        onCleanClick={onCleanClick}
        placeholder={searchBarPlaceholder}
        onChange={handleSearchChange}
      />

      {showAlertMsg && <InlineAlert description={alertMsg} variant={alertVariant} />}
    </div>
  );
};
