import React, { useEffect } from 'react';
import cn from 'classnames';
import { Icon, IconCatalog } from '../../Icon/Icon';

export enum InlineAlertVariant {
  neutral = 'neutral',
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export enum InlineAlertAppearance {
  subtle = 'subtle',
  strong = 'strong',
}

const Variants: Record<InlineAlertVariant, Record<InlineAlertAppearance, string>> = {
  [InlineAlertVariant.neutral]: {
    [InlineAlertAppearance.subtle]: 'e-bg-neutral-800',
    [InlineAlertAppearance.strong]: 'e-border e-border-primary-500 e-bg-neutral-800',
  },
  [InlineAlertVariant.success]: {
    [InlineAlertAppearance.subtle]: 'e-bg-neutral-800',
    [InlineAlertAppearance.strong]: 'e-border e-border-success-800 e-bg-neutral-800',
  },
  [InlineAlertVariant.warning]: {
    [InlineAlertAppearance.subtle]: 'e-bg-neutral-800',
    [InlineAlertAppearance.strong]: 'e-border e-border-warning-500 e-bg-neutral-800',
  },
  [InlineAlertVariant.error]: {
    [InlineAlertAppearance.subtle]: 'e-bg-neutral-800',
    [InlineAlertAppearance.strong]: 'e-border e-border-error-500 e-bg-neutral-800',
  },
};

const IconVariants: Record<InlineAlertVariant, IconCatalog> = {
  [InlineAlertVariant.neutral]: IconCatalog.infoFilled,
  [InlineAlertVariant.success]: IconCatalog.checkRounded,
  [InlineAlertVariant.warning]: IconCatalog.warning,
  [InlineAlertVariant.error]: IconCatalog.error,
};

const IconVariantColors: Record<InlineAlertVariant, string> = {
  [InlineAlertVariant.neutral]: 'e-text-primary-400',
  [InlineAlertVariant.success]: 'e-text-success-500',
  [InlineAlertVariant.warning]: 'e-text-warning-500',
  [InlineAlertVariant.error]: 'e-text-error-500',
};

export interface InlineAlertProps {
  /**
   * Set an idntifier when you have more than one Toast on the screen.
   */
  id?: string | number;

  /**
   * Descriptive label to be read to screenreaders
   */
  ariaLabel?: string;

  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Set a title
   */
  title?: string;

  /**
   * Set a description text
   */
  description?: string | JSX.Element;

  /**
   * The shape of the component.
   */
  variant?: InlineAlertVariant;

  /**
   * The appearance of the component.
   */
  appearance?: InlineAlertAppearance;

  /**
   * Whether the Tag has a contextual icon
   */
  hasIcon?: boolean;

  /**
   * Set a time to auto delete Toast
   */
  dismissInterval?: number;

  /**
   * Provide a handler that is called when the close button was clicked.
   */
  onClose?: () => void;
}

/**
 * In-line alerts display a non-modal message associated with objects in a view.
 * These are often used in form validation, providing a place to aggregate feedback
 * related to multiple fields.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2023-10-16
 */
export const InlineAlert = ({
  id,
  ariaLabel,
  className,
  title,
  description,
  appearance = InlineAlertAppearance.subtle,
  variant = InlineAlertVariant.neutral,
  hasIcon = true,
  dismissInterval,
  onClose,
}: InlineAlertProps) => {
  const classes = {
    container: cn(
      className,
      'e-p-4 e-flex e-flex-col e-gap-2',
      'e-w-full e-min-h-20',
      'e-rounded-lg',
      Variants[variant][appearance],
      {
        'e-items-start': title,
        'e-items-center': !title,
      },
    ),
    body: cn('e-flex e-w-full e-gap-2', {
      'e-justify-start e-content-center': title,
      'e-items-center': !title,
    }),
    icon: cn('e-w-6 e-h-6 e-shrink-0 e-grow-0', IconVariantColors[variant]),
    content: cn('e-flex e-items-start e-flex-col e-flex-1 e-gap-1.5'),
  };

  const renderDescription = () => {
    if (!description) return null;
    if (typeof description === 'string') {
      return (
        <div id={`description-${id}`} className="e-text-sm e-text-neutral-200">
          {description}
        </div>
      );
    }
    return description;
  };

  useEffect(() => {
    if (!dismissInterval) return;

    const timer = setTimeout(() => {
      if (onClose) onClose();
    }, dismissInterval);

    return () => clearTimeout(timer);
  }, []);

  /* Render JSX */
  return (
    <div
      className={classes.container}
      role="alert"
      aria-label={ariaLabel}
      aria-labelledby={`title-${id}`}
      aria-describedby={`description-${id}`}
      tabIndex={0}>
      <div className={classes.body}>
        {/* ICON */}
        {hasIcon && <Icon className={classes.icon} icon={IconVariants[variant]} width={32} height={32} />}

        {/* CONTENT */}
        <div className={classes.content}>
          {/* TITLE */}
          {title && (
            <div id={`title-${id}`} className="e-font-medium e-text-base e-text-base-white">
              {title}
            </div>
          )}

          {/* DESCRIPTION */}
          {renderDescription()}
        </div>
      </div>
    </div>
  );
};
