import React, { ChangeEvent, FocusEvent, SelectHTMLAttributes, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { Popover, PopoverPlacement } from '../../Overlays/Popovers/Popover/Popover';
import { setMaxHeightByOptions } from '../../../common/utils/helpers';
import { FormFieldState } from '../../../common/constants';
import { Input, SelectOption } from '../../../common/types/input';
import { usePopupMenu } from '../../../common/hooks';
import { Icon, IconCatalog } from '../../Icon/Icon';
import { Spinner, SpinnerSize, SpinnerVariant } from '../../Loaders/Spinner/Spinner';

export interface SelectProps
  extends Input,
    Omit<SelectHTMLAttributes<HTMLSelectElement>, 'required' | 'onChange' | 'onFocus' | 'onBlur'> {
  /**
   * Set a proload list of options.
   */
  preloadOptions?: Array<SelectOption>;

  /**
   * Set the select options list.
   */
  options: Array<SelectOption>;

  /**
   * Specify an optional className to be added to the popover menu.
   */
  menuClassName?: string;

  /**
   * Whether the options on the list are loading.
   */
  isOptionsLoading?: boolean;

  /**
   * Provide a handler that is called when the select was clicked.
   */
  onSelectClick?: () => void;

  /**
   * Provide a handler that is called when an option was selected.
   */
  onChange?: (selectedOption: SelectOption, name: string | undefined) => void;

  /**
   * The callback to get notified when the cursor focus the Select component.
   */
  onFocus?: (event: ChangeEvent<HTMLDivElement>) => void;

  /**
   * The callback to get notified when the cursor blur the Select component.
   */
  onBlur?: (event: ChangeEvent<HTMLDivElement>) => void;
}

/**
 * This component could be thought of as a styled native select tag, but adding to it more functionalities such as filtering, multi selecting, among others. The source of the Select (Dropdown) is a list of options that the user can choose from and apply to an input field to later be submitted.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2022-05-26
 */
export const Select = React.forwardRef<HTMLDivElement, SelectProps>(
  (
    {
      id,
      name,
      options = [],
      placeholder,
      label,
      assistiveText,
      value,
      fieldState = FormFieldState.default,
      isDisabled = false,
      isRequired = false,
      isFullWidth = false,
      isLoading = false,
      menuClassName,
      className = '',
      onChange,
      onBlur,
      onFocus,
      onSelectClick,
      dataTestId,
    },
    ref,
  ) => {
    const [selectedOption, setSelectedOption] = useState<SelectOption | undefined>();
    const [isFocused, setIsFocused] = useState(false);
    const defaultOption = useMemo(() => options.find((option) => option.value === value), [options, value]);
    const isSelectActive = fieldState === FormFieldState.active && !isDisabled;

    const classes = {
      container: cn(className),
      select: cn(
        'e-flex e-p-2 e-pl-4 e-cursor-pointer',
        'e-border e-rounded-lg e-appearance-none e-outline-none e-truncate',
        'e-h-10',
        {
          'e-w-full': isFullWidth,
          'e-bg-neutral-700 e-text-neutral-50': !isDisabled,
          'e-text-neutral-300 e-bg-neutral-800 e-border-transparent e-cursor-default': isDisabled,
          'e-border-neutral-400': fieldState === FormFieldState.default && !isDisabled,
          'e-border-error-500': fieldState === FormFieldState.error && !isDisabled,
          'e-border-primary-500': (fieldState === FormFieldState.default && isFocused) || isSelectActive,
        },
      ),
      menu: cn(menuClassName),
      option: (item: SelectOption, index: number) =>
        cn('e-flex e-items-center e-space-x-3', 'e-px-3 e-py-4', 'hover:e-bg-neutral-700', {
          'e-bg-neutral-700': index === cursor || item.value === selectedOption?.value,
        }),
      placeholder: cn('e-flex e-flex-wrap e-items-center e-text-base e-truncate', {
        'e-text-neutral-300': !selectedOption?.label,
      }),
      endContainer: cn('e-flex e-items-center e-space-x-2 e-self-stretch e-flex-shrink-0 e-pl-1 e-ml-auto'),
      assistiveText: cn('e-mt-2 e-text-xs e-font-extra-light', {
        'e-text-neutral-200': fieldState === FormFieldState.default,
        'e-text-error-500': fieldState === FormFieldState.error,
      }),
    };

    const [isOpen, setIsOpen] = useState(false);
    const { refs, cursor, onKeyDown, setOptionCursor } = usePopupMenu({
      options,
      onOptionChange: (option) => {
        setSelectedOption(option);
        if (onChange) onChange(option, name);
      },
    });

    useEffect(() => {
      setSelectedOption(defaultOption);
    }, [defaultOption]);

    const handleOptionClick = (option: SelectOption) => (): void => {
      setOptionCursor(option);
      setSelectedOption(option);
      setIsOpen(false);
      if (onChange) onChange(option, name);
    };

    const handleSelectClick = () => {
      if (isDisabled) return;

      setIsOpen(!isOpen);
      if (onSelectClick) onSelectClick();
    };

    const handleSelectBlur = (event: FocusEvent<HTMLDivElement>): void => {
      setIsFocused(false);
      if (onBlur) onBlur(event);
    };

    const handleSelectFocus = (event: FocusEvent<HTMLDivElement>): void => {
      if (isDisabled) return;

      setIsFocused(true);
      if (onFocus) onFocus(event);
    };

    const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (isOpen) onKeyDown(event);
    };

    const renderOptions = options.map((option, index) => (
      <div
        title={option.label}
        ref={refs[option.value]}
        key={index}
        role="option"
        className={classes.option(option, index)}
        onClick={handleOptionClick(option)}>
        <div className="e-flex e-w-full e-text-neutral-50 e-text-sm e-font-medium e-truncate">{option.label}</div>
      </div>
    ));

    const popoverContent = (
      <div
        role="listbox"
        style={setMaxHeightByOptions({ listLength: options.length, maxOptions: 7, maxHeight: '300px' })}
        className="e-scrollbar-w-2 e-scrollbar-thumb-neutral-400 e-scrollbar-track-neutral-700 e-scrollbar-thumb-rounded-lg e-flex-grow e-w-full e-overflow-y-auto">
        {renderOptions}
      </div>
    );

    return (
      <div className={classes.container}>
        {label && (
          <label className="e-mb-2 e-text-neutral-50 e-text-sm e-font-regular" htmlFor={id}>
            {label}
            {isRequired && '*'}
          </label>
        )}
        <Popover
          isOpen={isOpen}
          content={popoverContent}
          placement={PopoverPlacement.bottomStart}
          hasArrow={false}
          menuClassName={classes.menu}
          menuFullWidth>
          <div
            id={id}
            ref={ref}
            role="button"
            tabIndex={0}
            className={classes.select}
            aria-disabled={isDisabled}
            aria-haspopup="listbox"
            aria-expanded={isOpen}
            data-testid={dataTestId}
            onClick={handleSelectClick}
            onKeyPress={handleSelectClick}
            onFocus={handleSelectFocus}
            onKeyDown={handleOnKeyDown}
            onBlur={handleSelectBlur}>
            {/* PLACEHOLDER AND SELECTED OPTION TEXT */}
            <div className={classes.placeholder}>{selectedOption?.label ?? placeholder}</div>

            <div className={classes.endContainer}>
              {/* LOADING SPINNER */}
              {isLoading && <Spinner size={SpinnerSize.xs} variant={SpinnerVariant.neutral} />}

              {/* CHEVRON ICON */}
              <Icon icon={isOpen ? IconCatalog.chevronUp : IconCatalog.chevronDown} />
            </div>
          </div>
        </Popover>
        {assistiveText && <span className={classes.assistiveText}>{assistiveText}</span>}
      </div>
    );
  },
);
