import React, { forwardRef } from 'react';
import cn from 'classnames';
import { Content as RadixContent, PopoverContentProps as RadixPopoverContentProps } from '@radix-ui/react-popover';

export interface TooltipContentProps extends RadixPopoverContentProps {
  /**
   * Make the content full width on mobile
   */
  isFullWidthOnMobile?: boolean;
}

export const Content = forwardRef<HTMLDivElement, TooltipContentProps>((props, forwardedRef) => {
  const { className, children, isFullWidthOnMobile = false, ...rest } = props;
  const classes = {
    container: cn(
      'e-shadow-lg e-bg-neutral-600 e-rounded-lg',
      'e-border e-border-neutral-500 e-outline-none',
      'e-text-base-white e-text-caption e-text-center',
      'e-transition e-duration-100 e-ease-out',
      {
        'e-w-screen sm:e-w-auto': isFullWidthOnMobile,
      },
      className,
    ),
  };
  return (
    <RadixContent className={classes.container} ref={forwardedRef} {...rest}>
      {children}
    </RadixContent>
  );
});
