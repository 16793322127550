import React from 'react';
import cn from 'classnames';
import { Scrollable } from '../../../Scrollable/Scrollable';

export interface ModalBodyProps {
  /**
   * Elements to display inside the Modal.
   */
  children: React.ReactNode;

  /**
   * Specify an optional className to be added to the body section.
   */
  className?: string;

  /**
   * Flag to turn on/off the scrollable body content
   */
  isBodyScrollable?: boolean;
}

/**
 * `ModalBody` represents the body section of a modal
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2023-03-21
 */
export const ModalBody = ({ className, isBodyScrollable = true, children }: ModalBodyProps) => {
  const classes = {
    body: cn('e-px-6 e-relative e-flex-auto', className),
  };

  if (isBodyScrollable) return <Scrollable className={classes.body}>{children}</Scrollable>;

  return <div className={classes.body}>{children}</div>;
};
