import React, { HTMLAttributes, ReactNode, useState } from 'react';
import cn from 'classnames';
import { Icon, IconCatalog } from '../Icon/Icon';

export enum TagVariant {
  primary = 'primary',
  secondary = 'secondary',
  neutral = 'neutral',
  success = 'success',
  warning = 'warning',
  error = 'error',
  transparent = 'transparent',
}

const Variants: Record<TagVariant, string> = {
  [TagVariant.primary]: 'e-bg-primary-900 e-text-primary-50',
  [TagVariant.secondary]: 'e-bg-secondary-900 e-text-secondary-50',
  [TagVariant.neutral]: 'e-bg-neutral-500 e-text-neutral-50',
  [TagVariant.success]: 'e-bg-success-800 e-text-success-50',
  [TagVariant.warning]: 'e-bg-warning-800 e-text-warning-50',
  [TagVariant.error]: 'e-bg-error-800 e-text-error-50',
  [TagVariant.transparent]: 'e-bg-transparent e-text-primary-50',
};

const InvertVariants: Record<TagVariant, string> = {
  [TagVariant.primary]: 'e-bg-primary-500 e-text-base-white',
  [TagVariant.secondary]: 'e-bg-secondary-700 e-text-secondary-50',
  [TagVariant.neutral]: 'e-bg-neutral-50 e-text-neutral-500',
  [TagVariant.success]: 'e-bg-success-900 e-text-success-500',
  [TagVariant.warning]: 'e-bg-warning-900 e-text-warning-500',
  [TagVariant.error]: 'e-bg-error-900 e-text-error-500',
  [TagVariant.transparent]: 'e-bg-transparent e-text-primary-50',
};

const StrokeVariants: Record<TagVariant, string> = {
  [TagVariant.primary]: 'e-border e-border-primary-500',
  [TagVariant.secondary]: 'e-border e-border-secondary-500',
  [TagVariant.neutral]: 'e-border e-border-neutral-400',
  [TagVariant.success]: 'e-border e-border-success-400',
  [TagVariant.warning]: 'e-border e-border-warning-500',
  [TagVariant.error]: 'e-border e-border-error-500',
  [TagVariant.transparent]: 'e-border e-text-primary-50',
};

const StrokeInvertVariants: Record<TagVariant, string> = {
  [TagVariant.primary]: 'e-border e-border-primary-200',
  [TagVariant.secondary]: 'e-border e-border-secondary-200',
  [TagVariant.neutral]: 'e-border e-border-neutral-400',
  [TagVariant.success]: 'e-border e-border-success-500',
  [TagVariant.warning]: 'e-border e-border-warning-500',
  [TagVariant.error]: 'e-border e-border-error-500',
  [TagVariant.transparent]: 'e-border e-border-primary-50',
};

const InvertIconVariants: Record<TagVariant, string> = {
  [TagVariant.primary]: 'e-text-neutral-50',
  [TagVariant.secondary]: 'e-text-neutral-50',
  [TagVariant.neutral]: 'e-text-neutral-500',
  [TagVariant.success]: 'e-text-neutral-50',
  [TagVariant.warning]: 'e-text-neutral-50',
  [TagVariant.error]: 'e-text-neutral-50',
  [TagVariant.transparent]: 'e-text-primary-50',
};

export interface TagProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Set the Tag content
   */
  children: ReactNode;

  /**
   * The shape of the component. It determines the importance in the hierarchy, for example, the contained button commands the most attention
   */
  variant?: TagVariant;

  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Whether the Tag has a close button
   */
  hasCloseBtn?: boolean;

  /**
   * Whether the Tag has an invert style
   */
  invert?: boolean;

  /**
   * Whether the Tag has a border
   */
  hasStroke?: boolean;

  /**
   * Provide a handler that is called when the close button was clicked.
   */
  onCloseClick?: (event?: React.SyntheticEvent) => void;

  /**
   * Specify an optional test ID to use on e2e tests.
   */
  dataTestId?: string;
}

/**
 * The Tag component is useful to emphasize information to the user, works best with single word values.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2022-05-30
 */
export const Tag = ({
  children,
  variant = TagVariant.primary,
  hasCloseBtn = false,
  invert = false,
  hasStroke = false,
  className,
  dataTestId,
  onCloseClick,
  ...restProps
}: TagProps) => {
  const [tagContent, setTagContent] = useState('');

  const setStrokeColor = () => {
    if (!hasStroke) return '';
    if (invert) return StrokeInvertVariants[variant];
    return StrokeVariants[variant];
  };

  const classes = {
    tag: cn(
      className,
      'e-items-center e-relative e-overflow-hidden',
      'e-text-center e-whitespace-nowrap',
      'e-transition e-duration-100 e-ease-out',
      'e-rounded-2xl',
      invert ? InvertVariants[variant] : Variants[variant],
      setStrokeColor(),
      {
        'e-inline-flex e-py-1 e-px-2': tagContent.length > 1,
        'e-w-6 e-h-6 e-flex e-justify-center e-flex-grow-0 e-flex-shrink-0': tagContent.length === 1,
      },
    ),
    endIcon: cn('e-cursor-pointer e-flex-none', invert && InvertIconVariants[variant], {
      'e-ml-1': hasCloseBtn,
    }),
  };

  const handleCloseBtnClick = (event: React.SyntheticEvent) => {
    if (onCloseClick) onCloseClick(event);
  };

  const handleTagRef = (ref: HTMLDivElement | null) => {
    if (!ref) return;
    setTagContent(ref.textContent as string);
  };

  /* Render JSX */
  return (
    <div {...restProps} ref={(ref) => handleTagRef(ref)} data-testid={dataTestId} className={classes.tag}>
      <span className="e-text-xs e-font-medium e-truncate">{children}</span>
      {hasCloseBtn && (
        <Icon
          className={classes.endIcon}
          icon={IconCatalog.close}
          width={16}
          height={16}
          onClick={handleCloseBtnClick}
        />
      )}
    </div>
  );
};
