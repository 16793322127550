import React, { useEffect, useRef } from 'react';
import { Icon, IconCatalog } from '../../../Icon/Icon';
import cn from 'classnames';
import { Locale, SelectOption, useOnClickOutsideV2 } from '../../../../common';
import { SelectDropdown } from '../../../Inputs/SelectDropdown/SelectDropdown';
import { DataTableEditCellSizes, DatatableEditCellVariant } from '..';
import { SelectDropdownStatelessProps } from '../../../Inputs';

export interface QuickEditListItemProps extends Omit<SelectDropdownStatelessProps, 'options'> {
  /**
   * Determines if the cell is disabled. If true, the cell will be displayed as disabled.
   */
  isDisabled?: boolean;

  /**
   * The variant of the cell.
   */
  cellVariant: DatatableEditCellVariant;

  /**
   * The primary text to display. This text is placed as the main text of the cell.
   */
  primaryText: string;

  /**
   * The assistive text to display. This text is placed below the primary text.
   */
  assistiveText?: string;

  /**
   * The function to call when the edit icon is clicked.
   */
  onEditClick?: (itemName?: string) => void;

  /**
   * Optional options to display in the Select on local cell when editing mode is enabled.
   */
  options?: SelectOption[];

  /**
   * This callback is executed whenever an option of the Select variant is changed.
   */
  onOptionChange?: (option: SelectOption) => void;

  /**
   * onEditing
   */
  onEditingStateChange?: (isLocalEditing: boolean) => void;

  /**
   * This value is added to the select input when editing mode is enabled.
   */
  selectValue?: string;

  /**
   * Select dropdown class name.
   */
  selectClassName?: string;

  /**
   * Boolean flag to determine if the list item should be hidden. If passed, the component will return null.
   */
  shouldHideListItem?: boolean;

  /**
   * The size of the QuickEditListItem. It can be XS or SM.
   */
  size?: DataTableEditCellSizes;

  /**
   * The name of the item. This is used to identify the item when editing mode is enabled, and it is used
   * to add as the main id of the current item. If not provided, a custom default string is added instead.
   */
  itemName?: string;
}

export const QuickEditListItem = ({ assistiveText, shouldHideListItem, ...restOfProps }: QuickEditListItemProps) => {
  const classes = {
    assistiveText: cn('e-text-xs e-text-neutral-300 e-truncate'),
  };
  const renderAssistiveText = (assistiveText: string | undefined) => {
    if (!assistiveText || shouldHideListItem) return null;
    return <p className={classes.assistiveText}>{assistiveText}</p>;
  };

  // If the item should be hidden, return null.
  if (shouldHideListItem) return null;

  return (
    <>
      <ActionableCell {...restOfProps} />
      {renderAssistiveText(assistiveText)}
    </>
  );
};

const ActionableCell = ({
  isDisabled,
  cellVariant,
  primaryText,
  onEditClick,
  onOptionChange,
  onInputChange,
  locale = Locale.es,
  options,
  selectValue,
  itemName,
  size,
  selectClassName,
  onEditingStateChange,
  hasMoreResults,
  blockBodyScroll,
  preloadOptions,
  onLoadMore,
}: QuickEditListItemProps) => {
  const [isHovering, setIsHovering] = React.useState(false);
  const [isLocalEditing, setIsLocalEditing] = React.useState(false);
  const selectRef = useRef<HTMLInputElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dataTableEditRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (onEditingStateChange) onEditingStateChange(isLocalEditing);
  }, [isLocalEditing]);

  const classes = {
    iconClasses: cn('e-text-primary-300', 'e-opacity-0', {
      'e-opacity-100': isHovering,
    }),
    assistiveText: cn('e-text-xs e-text-neutral-300 e-truncate'),
    description: cn('e-truncate e-mb-1 e-mr-1', {
      'e-text-base-white': cellVariant === DatatableEditCellVariant.ONLY_READ && !isDisabled,
      'e-text-xs': size === DataTableEditCellSizes.xs,
      'e-text-sm': size === DataTableEditCellSizes.sm,
      'e-text-neutral-300': isDisabled,
      'e-hidden': isLocalEditing,
      'hover:e-underline hover:e-cursor-pointer e-text-primary-300':
        cellVariant !== DatatableEditCellVariant.ONLY_READ && !isDisabled,
    }),
    selectClasses: cn(selectClassName, {
      'e-hidden': !isLocalEditing,
    }),
  };

  const handleNavBarHover = () => setIsHovering(true);

  const handleLocalEditClick = () => {
    if (onEditClick) onEditClick(itemName);

    // to trigger opening automatically the select when clicking on edit.
    setTimeout(() => {
      selectRef.current?.focus();
      buttonRef.current?.click();
    });

    // to avoid having permament hover when clicking on edit.
    setIsHovering(false);

    setIsLocalEditing(true);
  };

  const handleExternalEditClick = () => {
    if (onEditClick) onEditClick();
  };

  useOnClickOutsideV2([dataTableEditRef.current, menuRef], () => {
    setIsLocalEditing(false);
  });

  if (cellVariant === DatatableEditCellVariant.ONLY_READ || isDisabled)
    return <p className={classes.description}>{primaryText}</p>;

  if (cellVariant === DatatableEditCellVariant.EXTERNAL) {
    return (
      <div className="e-flex">
        <button
          onMouseEnter={handleNavBarHover}
          onMouseLeave={() => setIsHovering(false)}
          onClick={handleExternalEditClick}
          className={classes.description}>
          {primaryText}
        </button>
        <Icon className={classes.iconClasses} height={16} width={16} icon={IconCatalog.arrowExternal} />
      </div>
    );
  }

  // If we don't send options, don't render the select.
  if (!options) return <p className={classes.description}>{primaryText}</p>;

  const handleChange = (option: SelectOption) => {
    if (onOptionChange) onOptionChange(option);

    setIsLocalEditing(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onInputChange) onInputChange(event);
  };

  return (
    <div className="e-flex" ref={dataTableEditRef}>
      <button
        onClick={handleLocalEditClick}
        onMouseEnter={handleNavBarHover}
        onMouseLeave={() => setIsHovering(false)}
        className={classes.description}>
        {primaryText}
      </button>
      <SelectDropdown
        blockBodyScroll={blockBodyScroll}
        onLoadMore={onLoadMore}
        preloadOptions={preloadOptions}
        hasMoreResults={hasMoreResults}
        options={options}
        ref={selectRef}
        menuContainerRef={menuRef}
        shouldDisplayInputValue={false}
        shouldClearQuery
        triggerRef={buttonRef}
        className={classes.selectClasses}
        locale={locale as Locale}
        value={selectValue || ''}
        onInputChange={handleInputChange}
        onChange={handleChange}
      />

      <Icon className={classes.iconClasses} height={16} width={16} icon={IconCatalog.edit} />
    </div>
  );
};
