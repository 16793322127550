import React, { useState } from 'react';
import cn from 'classnames';
import { QuickEditListItem, QuickEditListItemProps } from './QuickEditListItem';

interface DataTableEditCellProps {
  /**
   * The class name to apply to the very first container.
   */
  className?: string;

  listItems: Array<QuickEditListItemProps>;
}

export enum DatatableEditCellVariant {
  LOCAL,
  EXTERNAL,
  ONLY_READ,
}

export enum DataTableEditCellSizes {
  xs,
  sm,
}

export const DataTableEditCell = ({ className, listItems }: DataTableEditCellProps) => {
  const [activeListItem, setActiveListItem] = useState<string>('');
  const [isLocalEditing, setIsLocalEditing] = useState<boolean>(false);
  const classes = {
    container: cn(className),
    descriptionItem: cn('e-flex e-flex-col'),
  };

  const getItemName = (index: number, cellVariant: DatatableEditCellVariant, itemName?: string) => {
    const parsedIndex = index + 1;
    if (cellVariant === DatatableEditCellVariant.LOCAL)
      return itemName ? `${itemName}-local-${parsedIndex}` : `data-table-edit-local-${parsedIndex}`;

    if (cellVariant === DatatableEditCellVariant.EXTERNAL)
      return itemName ? `${itemName}-external-${parsedIndex}` : `data-table-edit-external-${parsedIndex}`;

    return itemName ? `${itemName}-only-read-${parsedIndex}` : `data-table-edit-only-read-${parsedIndex}`;
  };

  const handleEditingStateChange = (isLocalEditing: boolean) => {
    setIsLocalEditing(isLocalEditing);
  };

  return (
    <div className={classes.container}>
      {listItems?.map((item, index) => {
        const itemName = getItemName(index, item.cellVariant, item.itemName);
        const shouldHideListItem = activeListItem !== itemName && isLocalEditing;

        const handleItemEditClick = (itemName?: string) => {
          if (itemName) setActiveListItem(itemName);

          if (item.onEditClick) item.onEditClick();
        };

        return (
          <div key={index} className={classes.descriptionItem}>
            <QuickEditListItem
              {...item}
              shouldHideListItem={shouldHideListItem}
              itemName={itemName}
              onEditClick={handleItemEditClick}
              onEditingStateChange={handleEditingStateChange}
            />
          </div>
        );
      })}
    </div>
  );
};
