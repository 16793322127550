import React from 'react';
import { Anchor, Arrow, Portal, PopoverProps, Popover as RadixPopover, Root, Trigger } from '@radix-ui/react-popover';
import { Content } from './components';

/**
 * The Popover component displays floating informative and actionable content in relation to a target. Popovers appear either at the top, bottom, left, or right of their target.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2024-05-16
 */
export const BasePopover = (props: PopoverProps) => <RadixPopover {...props} />;
BasePopover.Root = Root;
BasePopover.Arrow = Arrow;
BasePopover.Anchor = Anchor;
BasePopover.Trigger = Trigger;
BasePopover.Portal = Portal;
BasePopover.Content = Content;

/**
 * Reference:
 * https://www.radix-ui.com/primitives/docs/components/popover
 */
