import React, { FocusEvent, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import {
  SelectDropdownStatelessProps,
  SelectDropdownStateless,
} from '../SelectDropdownStateless/SelectDropdownStateless';
import { SelectOption } from '../../../common';

export interface SelectDropdownProps extends SelectDropdownStatelessProps {
  /*
   * This callback is executed whenever a value is introuded into the input field.
   */
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;

  /**
   * This flag is used to determine if the query should be cleared when the dropdown is closed.
   */
  shouldClearQuery?: boolean;
}

/**
 * `SelectDropdown` represents a list from where to select an option
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2023-07-24
 */
export const SelectDropdown = React.forwardRef<HTMLInputElement, SelectDropdownProps>(
  (
    {
      className,
      menuClassName,
      isDisabled = false,
      onBlur,
      onChange,
      onFocus,
      onLoadMore,
      shouldClearQuery = false,
      options = [],
      value,
      onInputChange,
      ...restOfProps
    },
    inputRef,
  ) => {
    const defaultOption = useMemo(() => options.find((option) => option.value === value), [options, value]);
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const [query, setQuery] = useState('');

    const getMatchingQueryOptions = (option: SelectOption) =>
      option.label.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''));

    const filteredOptions = useMemo(() => {
      if (query === '') return options;
      return options.filter(getMatchingQueryOptions);
    }, [options, query]);

    const classes = { container: cn(className), menu: cn(menuClassName) };

    const handleInputBlur = (event: FocusEvent<HTMLDivElement>): void => {
      if (onBlur) onBlur(event);
    };

    const handleInputFocus = (event: FocusEvent<HTMLDivElement>): void => {
      if (isDisabled) return;

      if (onFocus) onFocus(event);
    };

    const handleOptionChange = (selectedOption: SelectOption, name?: string) => {
      setSelectedOption(selectedOption);
      if (onChange) onChange(selectedOption as SelectOption, name);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);

      if (onInputChange) onInputChange(event);
    };

    const handleChevronButtonClick = () => {
      // clears the search elements when closing or opening the dropdown by the click button.
      if (shouldClearQuery) setQuery('');
    };

    useEffect(() => setSelectedOption(defaultOption), [defaultOption]);

    return (
      <SelectDropdownStateless
        {...restOfProps}
        ref={inputRef}
        className={classes.container}
        menuClassName={classes.menu}
        value={selectedOption?.value || value}
        onChevronButtonClick={handleChevronButtonClick}
        options={filteredOptions}
        onBlur={handleInputBlur}
        onChange={handleOptionChange}
        onInputChange={handleInputChange}
        onFocus={handleInputFocus}
      />
    );
  },
);
