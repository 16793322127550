import React, { useMemo } from 'react';
import cn from 'classnames';
import { BasePopover } from '../BasePopover/BasePopover';

export enum PopoverPosition {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}

export interface SimplePopoverProps {
  /**
   * Specify an optional className to be added to the content component
   */
  contentClassName?: string;

  /**
   * The content displayed inside the popover.
   */
  content: React.ReactNode;

  /**
   * The trigger of the tooltip
   */
  children: React.ReactNode;

  /**
   * The position of the popover
   */
  position?: PopoverPosition;

  /**
   * The offset of the popover
   */
  positionOffset?: number;

  /**
   * Set if the popover is going to be cotrolled by the open property
   */
  controlled?: boolean;

  /**
   * Set if a popover is going to be open, this option is only avaylable when controlled is active
   */
  open?: boolean;

  /**
   * Prevents the popover from closing when the user clicks the trigger
   */
  preventClose?: boolean;

  /**
   * Make the content full width on mobile
   */
  isFullWidthOnMobile?: boolean;

  /**
   * Callback when the tootip changed it's state
   */
  onOpenChange?: (open: boolean) => void;
}

export const SimplePopover = ({
  contentClassName,
  open,
  children,
  content,
  controlled,
  preventClose = false,
  position = PopoverPosition.RIGHT,
  positionOffset = 10,
  isFullWidthOnMobile = false,
  onOpenChange,
}: SimplePopoverProps) => {
  const classes = {
    content: cn(contentClassName),
  };

  const handlePointerDown = (event: { preventDefault: () => void }) => {
    if (preventClose) event.preventDefault();
  };

  const handleOnOpenChange = (open: boolean) => {
    if (onOpenChange) onOpenChange(open);
  };

  const rootProperties = useMemo(() => {
    if (controlled)
      return {
        onOpenChange: handleOnOpenChange,
        controlled,
        open,
      };
    return { onOpenChange: handleOnOpenChange };
  }, [controlled, open]);

  return (
    <BasePopover.Root {...rootProperties}>
      <BasePopover.Trigger asChild>{children}</BasePopover.Trigger>
      <BasePopover.Portal>
        <BasePopover.Content
          className={classes.content}
          side={position}
          sideOffset={positionOffset}
          isFullWidthOnMobile={isFullWidthOnMobile}
          onPointerDownOutside={handlePointerDown}>
          {content}
        </BasePopover.Content>
      </BasePopover.Portal>
    </BasePopover.Root>
  );
};

export default SimplePopover;
