import React from 'react';
import cn from 'classnames';
import { TabsList as RadixTabsList, TabsListProps as RadixTabsListProps } from '@radix-ui/react-tabs';

export interface TabsListProps extends RadixTabsListProps {}

export const TabsList = React.forwardRef<HTMLDivElement, TabsListProps>((props, forwardedRef) => {
  const { className, children, ...rest } = props;
  const classes = {
    container: cn(className),
  };
  return (
    <RadixTabsList className={classes.container} ref={forwardedRef} {...rest}>
      {children}
    </RadixTabsList>
  );
});
